<template>
  <div>
    <select-account-cashier v-model="transfer.from" label="Origem -" />
    <select-account-cashier v-model="transfer.dest" label="Destino -" />
    <select-type-payment v-model="transfer.type_payment_id" :use-id="true" />
    <sig-input-currency v-model="transfer.value" label="Valor" />
    <vs-divider />
    <div class="mt-4 flex flex-wrap items-center justify-end">
      <vs-button class="ml-4" color="danger" @click="executeTransfer">Transferir</vs-button>
    </div>
  </div>
</template>

<script>
import SelectAccountCashier from '@/components/cashier/account/SelectAccountCashier'
import SelectSpecie from '@/components/financial/specie/SelectSpecie'
import SelectTypePayment from '@/components/financial/typePayment/SelectTypePayment'
import axios from '@/axios'

export default {
  components: {
    SelectAccountCashier,
    SelectSpecie,
    SelectTypePayment
  },
  props: {
    cashierId:{}
  },
  data () {
    return {
      transfer: {
        from: null,
        dest: null,
        type_payment_id: null,
        value: 0
      }
    }
  },
  methods: {
    executeTransfer () {
      // validar primeiro
      if (!this.validate()) return

      // realizar transferencia
      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/cashier/transfer/create`, this.transfer)
        .then(() => {
          this.$emit('saved')
          this.mixinNotify('Operação executada com sucesso.')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
      return true
    },
    validate () {
      // lista de funcoes de validação
      const rules = [
        () => { // validar se vazio
          const list = ['from', 'dest', 'type_payment_id', 'value']
          for (const key of list) {
            if (!this.transfer[key]) {
              this.mixinNotify('Preencha os campos obrigatórios', 'warning')
              return false
            }
          }
          return true
        }, // validar se origem e destino sao iguais
        () => {
          for (const key in this.transfer.from) {
            if (this.transfer.from[key] && this.transfer.from[key] === this.transfer.dest[key]) {
              this.mixinNotify('Origem e destino não podem ser iguais', 'warning')
              return false
            }
          }
          return true
        }, // origem ou destino deve ser caixa
        () => {
          if (this.cashierId && this.transfer.from.cashier_id !== this.cashierId && this.transfer.dest.cashier_id !== this.cashierId) {
            this.mixinNotify('A origem ou o destino deve ser seu caixa', 'warning')
            return false
          }
          return true
        }
      ]

      for (const validator of rules) {
        if (!validator()) return false
      }
      return true
    }
  }
}

</script>

