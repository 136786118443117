var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    [
      _c(
        "vs-col",
        [
          _c(
            "vs-row",
            { staticClass: "flex items-end" },
            [
              _c(
                "vs-col",
                {
                  staticClass: "flex-1",
                  attrs: { "vs-w": "2", "vs-type": "flex" },
                },
                [
                  _c("user-date-period-select", {
                    on: { confirm: _vm.filter },
                    model: {
                      value: _vm.params,
                      callback: function ($$v) {
                        _vm.params = $$v
                      },
                      expression: "params",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                [
                  _c("vs-divider"),
                  _c(
                    "vs-table",
                    {
                      attrs: {
                        stripe: "",
                        data: _vm.closure,
                        noDataText: "Sem movimentação",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, index) {
                              return _c(
                                "vs-tr",
                                {
                                  key: index,
                                  attrs: {
                                    state: tr.NAME === "TOTAL" ? "success" : "",
                                  },
                                },
                                [
                                  _c("vs-td", { attrs: { data: tr.NAME } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(tr.NAME) +
                                        "\n              "
                                    ),
                                  ]),
                                  _c("vs-td", { attrs: { data: tr.IN } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.mixinCurrency(tr.IN)) +
                                        "\n              "
                                    ),
                                  ]),
                                  _c("vs-td", { attrs: { data: tr.OUT } }, [
                                    _vm._v(
                                      " R$ " +
                                        _vm._s(_vm.mixinCurrency(tr.OUT)) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", { attrs: { data: tr.BALANCE } }, [
                                    _vm._v(
                                      "\n                R$ " +
                                        _vm._s(_vm.mixinCurrency(tr.BALANCE)) +
                                        "\n              "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("h3", [_vm._v("TIPO DE PAGAMENTO")]),
                      ]),
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [_vm._v("Espécie")]),
                          _c("vs-th", [_vm._v("Entrada")]),
                          _c("vs-th", [_vm._v("Saída")]),
                          _c("vs-th", [_vm._v("Saldo")]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }