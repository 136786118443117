<template>
  <div>
    <div class="vs-input--label">
      <sig-button-info title="Conta ou Caixa" text="Permite selecionar contas ou caixas abertos. Clique na opção desejada: Caixa - Conta.">
        <div class="inline-flex">
          {{ label }}
          <span v-if="label">
            &nbsp;
          </span>
          <span :class="classAccount" @click="changeSelect" >Conta</span>
          &nbsp;-&nbsp;
          <span :class="classCashier" @click="changeSelect" >Caixa</span>
        </div>
      </sig-button-info>
    </div>
    <select-account  v-model="account" :label="false" v-if="showAccount" :use-id="true" />
    <select-cashier  v-model="cashier" :label="false" v-if="!showAccount" :use-id="true" />
  </div>
</template>

<script>
import SelectAccount from '@/components/financial/account/SelectAccount'
import SelectCashier from '@/components/cashier/SelectCashier'

export default {
  components: {
    SelectAccount,
    SelectCashier
  },
  props: {
    value: {},
    label: {}
  },
  data () {
    return {
      showAccount: true,
      classAccount: 'text-primary cursor-pointer',
      classCashier: 'text-secondary cursor-pointer'
    }
  },
  computed: {
    account: {
      get () {
        return this.value ? this.value.account_id : null
      },
      set (value) {
        this.$emit('input', {account_id: value, cashier_id: null})
      }
    },
    cashier: {
      get () {
        return this.value ? this.value.cashier_id : null
      },
      set (value) {
        this.$emit('input', {account_id: null, cashier_id: value})
      }
    }
  },
  methods: {
    changeSelect () {
      this.showAccount = !this.showAccount
      const temp = this.classAccount
      this.classAccount = this.classCashier
      this.classCashier = temp
    }
  }
}
</script>
