var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vs-input--label" },
        [
          _c(
            "sig-button-info",
            {
              attrs: {
                title: "Conta ou Caixa",
                text: "Permite selecionar contas ou caixas abertos. Clique na opção desejada: Caixa - Conta.",
              },
            },
            [
              _c("div", { staticClass: "inline-flex" }, [
                _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
                _vm.label
                  ? _c("span", [_vm._v("\n           \n        ")])
                  : _vm._e(),
                _c(
                  "span",
                  { class: _vm.classAccount, on: { click: _vm.changeSelect } },
                  [_vm._v("Conta")]
                ),
                _vm._v("\n         - \n        "),
                _c(
                  "span",
                  { class: _vm.classCashier, on: { click: _vm.changeSelect } },
                  [_vm._v("Caixa")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.showAccount
        ? _c("select-account", {
            attrs: { label: false, "use-id": true },
            model: {
              value: _vm.account,
              callback: function ($$v) {
                _vm.account = $$v
              },
              expression: "account",
            },
          })
        : _vm._e(),
      !_vm.showAccount
        ? _c("select-cashier", {
            attrs: { label: false, "use-id": true },
            model: {
              value: _vm.cashier,
              callback: function ($$v) {
                _vm.cashier = $$v
              },
              expression: "cashier",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }