var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c(
            "vs-row",
            { staticClass: "w-full" },
            [
              _c(
                "vs-col",
                { staticClass: "w-full" },
                [
                  _c("user-search-select", {
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "flex items-end w-full", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "flex-1",
                  attrs: { "vs-w": "8", "vs-type": "flex" },
                },
                [
                  _c("sig-date-range", {
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-w": "4" } },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("Buscar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }