<template>
  <div class="w-full">
      <label class="vs-input--label" v-if="label" >{{ label }}</label>
      <v-select
        v-model="specie"
        :clearable="false"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Espécie"
        v-validate.initial="'required'"
        name="specie"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('specie')">{{
        errors.first('specie')
      }}</span>
  </div>
</template>

<script>
import {specieOptions} from '@/store/financial/specie/specie'
// Store Module
export default {
  props: {
    value: {
    },
    disabled:{},
    multiple: {
      default: false
    },
    useId:{
      default: false
    },
    filter: {},
    label: {
      default: 'Espécie'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    specie: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    options () {
      return specieOptions
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('specie')
    }
  }
}
</script>
