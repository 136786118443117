var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    [
      _c(
        "vs-col",
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.searchClosure },
            },
            [_vm._v("Resumo")]
          ),
        ],
        1
      ),
      _c(
        "sig-popup",
        {
          model: {
            value: _vm.popupClosure,
            callback: function ($$v) {
              _vm.popupClosure = $$v
            },
            expression: "popupClosure",
          },
        },
        [_c("cashier-closure", { ref: "closure" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }