var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    [
      _c(
        "vs-col",
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "6" } },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { staticClass: "pr-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "date_format:yyyy-MM-dd",
                                expression: "'date_format:yyyy-MM-dd'",
                              },
                            ],
                            ref: "startDate",
                            staticClass: "w-full",
                            attrs: {
                              type: "date",
                              label: "Data Inicial",
                              "data-vv-as": "Data Inicial",
                              name: "startDate",
                            },
                            on: { input: _vm.update },
                            model: {
                              value: _vm.start,
                              callback: function ($$v) {
                                _vm.start = $$v
                              },
                              expression: "start",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("startDate"),
                                  expression: "errors.has('startDate')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("Data inicial deve ser menor que a final")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "pl-2", attrs: { "vs-w": "6" } },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value:
                          "date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate",
                        expression:
                          "\n            'date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate'\n          ",
                      },
                    ],
                    ref: "endDate",
                    staticClass: "w-full",
                    attrs: {
                      type: "date",
                      label: "Data Final",
                      "data-vv-delay": "500",
                      "data-vv-as": "Data Final",
                      name: "endDate",
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.end,
                      callback: function ($$v) {
                        _vm.end = $$v
                      },
                      expression: "end",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("endDate"),
                          expression: "errors.has('endDate')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("endDate")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }