<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <vs-row class="w-full">
        <vs-col class="w-full">
          <user-search-select v-model="user"/>
        </vs-col>
      </vs-row>
      <vs-row class="flex items-end w-full" vs-w="12">
        <vs-col vs-w="8" vs-type="flex" class="flex-1">
          <sig-date-range  v-model="date"/>
        </vs-col>
        <vs-col vs-w="4">
          <vs-button color="primary" type="filled" @click="confirm" class="ml-2">Buscar</vs-button>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import UserSearchSelect from './UserSearchSelect'
import SigDateRange from '@/components/sig/SigDateRange'

export default {
  components: {
    UserSearchSelect,
    SigDateRange
  },
  props: ['value', 'nameSelect', 'multiple'],
  data () {
    return {
      single: null,
      options: [],
      user: {},
      date: {}
    }
  },
  methods: {
    confirm () {
      this.value.users = this.user.data
      this.value.start = this.date.start
      this.value.end = this.date.end
      this.$emit('confirm', this.value)
    }
  }
}
</script>
