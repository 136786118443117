<template>
  <vs-row>
    <vs-col>
      <vs-row
        class="flex items-end "
      >
        <vs-col vs-w="2" vs-type="flex" class="flex-1" >
          <user-date-period-select v-model="params" @confirm="filter"/>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <vs-divider />
          <vs-table stripe :data="closure" noDataText="Sem movimentação">
            <template slot="header">
              <h3>TIPO DE PAGAMENTO</h3>
            </template>
            <template slot="thead">
              <vs-th>Espécie</vs-th>
              <vs-th>Entrada</vs-th>
              <vs-th>Saída</vs-th>
              <vs-th>Saldo</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :key="index"
                v-for="(tr, index) in data"
                :state="tr.NAME === 'TOTAL' ? 'success' : ''"
              >
                <vs-td :data="tr.NAME">
                  {{ tr.NAME }}
                </vs-td>

                <vs-td :data="tr.IN">
                  {{ mixinCurrency(tr.IN) }}
                </vs-td>

                <vs-td :data="tr.OUT"> R$ {{ mixinCurrency(tr.OUT) }} </vs-td>

                <vs-td :data="tr.BALANCE">
                  R$ {{ mixinCurrency(tr.BALANCE) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
import model from '@/store/cashier/cashFlow/model'
import UserDatePeriodSelect from '@/components/register/user/UserDatePeriodSelect'

export default {
  components: {
    UserDatePeriodSelect
  },
  props: ['value'],
  data () {
    return {
      model,
      params: {

      }
    }
  },
  computed: {
    closure () {
      return this.$store.getters[this.model.$store.getters.closure]
    },
    cashier () {
      return this.$store.state.myCashier.cashier
    }
  },
  methods: {
    search () {
      this.$vs.loading()
      this.$store.dispatch(this.model.$store.dispatch.closure, {
        id: this.cashier.id
      }).catch(this.mixinCatch).finally(this.mixinFinally)
    },
    filter () {
      this.$vs.loading()
      this.$store.dispatch(this.model.$store.dispatch.closure, this.params).catch(this.mixinCatch).finally(this.mixinFinally)
    }
  }
}
</script>
