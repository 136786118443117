var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("select-account-cashier", {
        attrs: { label: "Origem -" },
        model: {
          value: _vm.transfer.from,
          callback: function ($$v) {
            _vm.$set(_vm.transfer, "from", $$v)
          },
          expression: "transfer.from",
        },
      }),
      _c("select-account-cashier", {
        attrs: { label: "Destino -" },
        model: {
          value: _vm.transfer.dest,
          callback: function ($$v) {
            _vm.$set(_vm.transfer, "dest", $$v)
          },
          expression: "transfer.dest",
        },
      }),
      _c("select-type-payment", {
        attrs: { "use-id": true },
        model: {
          value: _vm.transfer.type_payment_id,
          callback: function ($$v) {
            _vm.$set(_vm.transfer, "type_payment_id", $$v)
          },
          expression: "transfer.type_payment_id",
        },
      }),
      _c("sig-input-currency", {
        attrs: { label: "Valor" },
        model: {
          value: _vm.transfer.value,
          callback: function ($$v) {
            _vm.$set(_vm.transfer, "value", $$v)
          },
          expression: "transfer.value",
        },
      }),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { color: "danger" },
              on: { click: _vm.executeTransfer },
            },
            [_vm._v("Transferir")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }