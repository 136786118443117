const specieEnum = {
  PIX: 'PIX',
  CARNE: 'CARNÊ',
  ALL: 'TODAS',
  CREDIT_CARD: 'CARTÃO DE CRÉDITO',
  DEBIT_CARD: 'CARTÃO DE DÉBITO',
  BANKSLIP: 'BOLETO',
  MONEY: 'DINHEIRO',
  INDEFINIDO: ''
}

const specieOptions = []

for (const key in specieEnum) {
  specieOptions.push({
    value: key,
    label: specieEnum[key]
  })
}

export { specieEnum, specieOptions }
