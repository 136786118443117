<template>
  <vs-row>
    <vs-col>
      <vs-button color="primary" type="filled" @click="searchClosure">Resumo</vs-button>
    </vs-col>
    <sig-popup v-model="popupClosure">
      <cashier-closure ref="closure"/>
    </sig-popup>
  </vs-row>
</template>

<script>

import CashierClosure from './CashierClosure'

export default {
  components: {
    CashierClosure
  },
  props: ['value'],
  data () {
    return {
      popupClosure: {
        active: false,
        title: 'Resumo de Movimentação'
      }
    }
  },
  methods:{
    searchClosure () {
      this.popupClosure.active = true
      this.$refs.closure.search()
    }
  }
}
</script>
