<template>
  <div class="w-full">
      <label class="vs-input--label" v-if="label" >{{ label }}</label>
      <v-select
        v-model="cashier"
        :clearable="false"
        :reduce="(option) => option.value"
        :options="allOptions"
        data-vv-as="Caixa"
        v-validate.initial="'required'"
        name="cashier"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('cashier')">{{
        errors.first('cashier')
      }}</span>
  </div>
</template>

<script>
// por enquanto esse compoenente é para selecionar caixas abertos
export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    disabled:{},
    multiple: {
      default: false
    },
    useId:{
      default: false
    },
    filter: {},
    label: {
      default: 'Caixa'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    cashier: {
      get () {
        if (Array.isArray(this.value) && this.value.length) {
          return this.value.map(e => e.id)
        }
        if (this.useId) { // no v-model foi passado um ID
          return this.value
        } else {
          return this.value && this.value.id ? this.value.id : null
        }
      },
      set (value) {
        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.allCashiers.filter(e => value.includes(e.id)))
          }
          this.$emit('input', this.allCashiers.find(e => e.id === value))
        }
      }
    },
    allCashiers () {
      return this.$store.state.cashier.open
    },
    allOptions () {
      return this.$store.state.cashier.open.map(e => ({value: e.id, label: `CAIXA DE ${e.user.name.toUpperCase()}` }))
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('cashier')
    }

  },
  mounted () {
    this.$store.dispatch('cashier/fetchOpenCashiers')
  }
}
</script>
