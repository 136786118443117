<template>
  <vs-row>
    <vs-col>
      <vs-row>
        <vs-col vs-w="6">
          <vs-row>
            <vs-col class="pr-2">
              <vs-input
                type="date"
                class="w-full"
                label="Data Inicial"
                v-model="start"
                @input="update"
                ref="startDate"
                v-validate="'date_format:yyyy-MM-dd'"
                data-vv-as="Data Inicial"
                name="startDate"
              />
              <span class="text-danger text-sm" v-show="errors.has('startDate')"
                >Data inicial deve ser menor que a final</span
              >
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="6" class="pl-2">
          <vs-input
            type="date"
            class="w-full"
            label="Data Final"
            ref="endDate"
            v-model="end"
            @input="update"
            data-vv-delay="500"
            v-validate="
              'date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate'
            "
            data-vv-as="Data Final"
            name="endDate"
          />
          <span class="text-danger text-sm" v-show="errors.has('endDate')">{{
            errors.first('endDate')
          }}</span>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  components: {},
  props: ['value'],
  data () {
    return {
      start: this.value.start || this.mixinToday(),
      end: this.value.start || this.mixinToday()
    }
  },
  methods: {
    update () {
      this.value.start = this.start
      this.value.end = this.end
    }
  },
  mounted () {
    this.value.start = this.start
    this.value.end = this.end
  }
}
</script>
